

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_CONTACTS, GET_CONTACTS } from '../store';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import { Contact } from '@/modules/conservatorshipToContact/types';
import { ApiResponse } from '@/components/types';
import ContactCard from './ContactCard.vue';
import ContactHeaderCard from './ContactHeaderCard.vue';
import ChangeContactDialog from '@/modules/conservatorshipToContact/components/ChangeContactDialog.vue';

const AddContactDialog = () => import('./AddContactDialog.vue');

const Conservatorship = namespace('conservatorshipToContact');

@Component({
  components: {
    AddContactDialog,
    ChangeContactDialog,
    ContactHeaderCard,
    ContactCard
  }
})
export default class ContactPlugin extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;

  @Conservatorship.Getter(GET_CONTACTS) getByConservatorship!: (id: string) => Contact[];
  @Conservatorship.Action(FETCH_CONTACTS) fetchContacts!: (id: string) => Promise<ApiResponse>;

  loading: boolean = false;
  loaded: boolean = false;

  contactToUpdate: Contact | null = null;

  get contacts(): Contact[] {
    return this.getByConservatorship(this.conservatorship.id);
  }

  @Watch('conservatorship', { immediate: true })
  async onConservatorshipChange(conservatorship: ConservatorshipDetails) {
    this.loading = true;

    await this.fetchContacts(conservatorship.id);

    this.loading = false;
    this.loaded = true;
  }
}
